import { createContext, useEffect, useState, ReactNode } from 'react';
import Onboard from 'bnc-onboard';
import { providers } from 'ethers';
import './Onboard.css';

const INFURA_KEY = process.env.REACT_APP_INFURA_ID;
const RPC_URL = `https://${
  process.env.REACT_APP_NETWORK_ID === '4' ? 'rinkeby' : 'polygon-mainnet'
}.infura.io/v3/${INFURA_KEY}`;
const APP_NAME = 'SmartMoney Whitelist';
const APP_URL = window.location.origin;
const CONTACT_EMAIL = 'info@smartmoney.finance';

const initState: {
  connected: boolean;
  provider: providers.Web3Provider | null;
  address: string;
  network: number;
} = {
  connected: false,
  provider: null,
  address: '',
  network: 0,
};

const EthersContext = createContext(initState);

export default function EthersContextWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const [state, setState] = useState(initState);
  useEffect(() => {
    const onboard = Onboard({
      networkId: process.env.REACT_APP_NETWORK_ID
        ? parseInt(process.env.REACT_APP_NETWORK_ID)
        : 4,
      subscriptions: {
        address: (address) =>
          setState((prevState) => ({
            ...prevState,
            address,
          })),
        network: (network) =>
          setState((prevState) => ({
            ...prevState,
            network,
          })),
        wallet: (wallet) => {
          if (wallet.provider) {
            setState((prevState) => ({
              ...prevState,
              provider: new providers.Web3Provider(wallet.provider),
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              connected: false,
              provider: null,
            }));
          }
        },
      },
      hideBranding: true,
      darkMode: true,
      walletSelect: {
        wallets: [
          { walletName: 'coinbase', preferred: true },
          { walletName: 'trust', preferred: true, rpcUrl: RPC_URL },
          { walletName: 'metamask', preferred: true },
          { walletName: 'authereum' },
          {
            walletName: 'trezor',
            appUrl: APP_URL,
            email: CONTACT_EMAIL,
            rpcUrl: RPC_URL,
          },
          {
            walletName: 'ledger',
            rpcUrl: RPC_URL,
          },
          {
            walletName: 'lattice',
            rpcUrl: RPC_URL,
            appName: APP_NAME,
          },
          {
            walletName: 'keepkey',
            rpcUrl: RPC_URL,
          },
          // {
          //   walletName: 'mewwallet',
          //   rpcUrl: RPC_URL,
          // },
          {
            walletName: 'cobovault',
            rpcUrl: RPC_URL,
            appName: APP_NAME,
          },
          {
            walletName: 'keystone',
            rpcUrl: RPC_URL,
            appName: APP_NAME,
          },
          // {
          //   walletName: "fortmatic",
          //   apiKey: FORTMATIC_KEY,
          //   preferred: true
          // },
          // {
          //   walletName: "portis",
          //   apiKey: PORTIS_KEY,
          //   preferred: true,
          //   label: 'Login with Email'
          // },
          {
            walletName: 'walletConnect',
            // infuraKey: { 4: INFURA_KEY },
            rpc: { 4: RPC_URL, 137: RPC_URL },
            preferred: true,
          },
          { walletName: 'opera' },
          { walletName: 'operaTouch' },
          { walletName: 'torus' },
          { walletName: 'status' },
          {
            walletName: 'walletLink',
            rpcUrl: RPC_URL,
            appName: APP_NAME,
            preferred: true,
          },
          { walletName: 'imToken', rpcUrl: RPC_URL },
          { walletName: 'meetone' },
          { walletName: 'mykey', rpcUrl: RPC_URL },
          { walletName: 'huobiwallet', rpcUrl: RPC_URL },
          { walletName: 'hyperpay' },
          { walletName: 'wallet.io', rpcUrl: RPC_URL },
          { walletName: 'atoken' },
          { walletName: 'frame' },
          { walletName: 'ownbit' },
          { walletName: 'alphawallet' },
          { walletName: 'gnosis', preferred: true },
          { walletName: 'xdefi' },
          { walletName: 'bitpie' },
          { walletName: 'binance' },
          { walletName: 'liquality' },
          { walletName: 'tally' },
          { walletName: 'blankwallet' },
          { walletName: 'mathwallet' },
          // { walletName: '1inch' },
          { walletName: 'ronin' },
        ],
      },
    });
    async function init() {
      const selected = await onboard.walletSelect();
      if (!selected) init();
      const checked = await onboard.walletCheck();
      if (!checked) init();
      setState((prevState) => ({
        ...prevState,
        connected: true,
      }));
    }
    init();
  }, []);
  return (
    <EthersContext.Provider value={state}>{children}</EthersContext.Provider>
  );
}

export { EthersContext };
