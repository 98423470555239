import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

export default function Questions() {
  return (
    <Stack spacing={2} margin={3} maxWidth={600}>
      <Box textAlign='center' marginTop={5}>
        <Typography variant='h6'>FAQ</Typography>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What is this?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            An on-chain whitelist of wallet addresses of KYC'd investors.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Why?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For use with SmartMoney projects and the wider DeFi community.
            Verifying that a user is accredited and not in a sanctioned country
            is required for many financial applications.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How does it work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            It leverages the Fractal Wallet and Fractal's KYC process. If you
            have already KYC'd through Fractal with the required credentials,
            you can install their wallet and take ownership of your credentials.
            Then come here to whitelist yourself. See{' '}
            <Link
              target='_blank'
              rel='noopener noreferrer'
              href='https://docs.developer.fractal.id/did-credentials#credential-fields'
            >
              Fractal Docs
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
