export const kycTypes = {
  1: 'plus+liveness+wallet',
  2: 'plus+liveness+wallet+sow',
  3: 'plus+selfie+wallet',
  4: 'plus+selfie+wallet+sow',
  5: 'plus+liveness+accreditation+wallet',
  6: 'plus+liveness+accreditation+wallet+sow',
  7: 'plus+accreditation+selfie+wallet', // plus+selfie+accreditation+wallet according to docs
  8: 'plus+selfie+accreditation+wallet+sow',
};

export const contractByNetwork = {
  4: '0x35b16E59A28deB75D1d3102f11DdE0E789043A21',
  137: '0xB226A3b2aD140bdfFAe2e0d5a250658861742aD4',
};
