import { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useSnackbar } from 'notistack';
import { utils } from 'ethers';
import { EthersContext } from './EthersContextWrapper';
import { SmartMoneyWhitelist__factory } from './contracts/generated';
import { contractByNetwork, kycTypes } from './constants';

export default function QueryWhitelist() {
  const [address, setAddress] = useState('');
  const { provider, network } = useContext(EthersContext);
  const { enqueueSnackbar } = useSnackbar();
  const [credentials, setCredentials] = useState<{
    verified: boolean;
    kycType: number;
    countryOfIDIssuance: number;
    countryOfResidence: number;
    rootHash: string;
  }>();

  return (
    <Paper>
      <Stack spacing={4} margin={3}>
        <Box textAlign='center'>
          <Typography variant='h6'>LOOKUP USER CREDENTIALS</Typography>
        </Box>
        <Stack direction='row' spacing={2} justifyContent='center' margin={5}>
          <TextField
            label='Address'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{ minWidth: 300 }}
          />
          <Button
            variant='outlined'
            sx={{ minWidth: 150 }}
            onClick={async () => {
              setCredentials(undefined);
              if (!address || !utils.isAddress(address)) {
                enqueueSnackbar('Invalid address');
                return;
              }
              if (!provider) {
                enqueueSnackbar('Wallet not connected');
                return;
              }
              const contract = SmartMoneyWhitelist__factory.connect(
                (contractByNetwork as any)[network],
                provider,
              );
              const creds = await contract.whitelist(address);
              if (creds.verified) {
                setCredentials(creds);
                enqueueSnackbar(`${address} is whitelisted!`);
              } else {
                enqueueSnackbar(`${address} is NOT whitelisted.`, {
                  variant: 'error',
                });
              }
            }}
          >
            Check Credentials
          </Button>
        </Stack>
        {credentials && (
          <Stack spacing={2} justifyContent='center' margin={5}>
            <Typography>
              Whitelisted: {credentials.verified ? 'Yes' : 'No'}
            </Typography>
            <Typography>
              KYC type: {credentials.kycType} -{' '}
              {(kycTypes as any)[credentials.kycType]}
            </Typography>
            <Typography>
              ID country code: {credentials.countryOfIDIssuance}
            </Typography>
            <Typography>
              Residence country code: {credentials.countryOfResidence}
            </Typography>
            <Typography>
              Info on KYC types and country codes:{' '}
              <Link
                target='_blank'
                rel='noopener noreferrer'
                href='https://docs.developer.fractal.id/did-credentials#credential-fields'
              >
                Fractal Docs
              </Link>
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}
