import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import EthersContextWrapper from './EthersContextWrapper';
import AddToWhitelist from './AddToWhitelist';
import QueryWhitelist from './QueryWhitelist';
import Questions from './Questions';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <EthersContextWrapper>
          <Container>
            <Stack spacing={5} sx={{ margin: 8 }} alignItems='center'>
              <Box textAlign='center' marginBottom={3}>
                <Typography variant='h4'>SMARTMONEY WHITELIST</Typography>
              </Box>
              <AddToWhitelist />
              <QueryWhitelist />
              <Questions />
            </Stack>
          </Container>
        </EthersContextWrapper>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
