import { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { utils } from 'ethers';
import { EthersContext } from './EthersContextWrapper';
import { SmartMoneyWhitelist__factory } from './contracts/generated';
import { kycTypes, contractByNetwork } from './constants';

export default function AddToWhitelist() {
  const [level, setLevel] = useState('plus+accreditation+selfie+wallet');
  const [pending, setPending] = useState(false);
  const { provider, address, network } = useContext(EthersContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Fractal = (window as any).Fractal;

  return (
    <Paper>
      <Stack spacing={4} margin={3}>
        <Box textAlign='center'>
          <Typography variant='h6'>
            ADD YOUR CREDENTIALS TO WHITELIST
          </Typography>
        </Box>
        <Stack direction='row' spacing={2} justifyContent='center' margin={5}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel>Credentials</InputLabel>
            <Select
              value={level}
              label='Credentials'
              onChange={(e) => setLevel(e.target.value)}
              disabled={pending}
            >
              {Object.values(kycTypes).map((kycType, idx) => (
                <MenuItem key={idx} value={kycType}>
                  {kycType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='outlined'
            sx={{ minWidth: 150 }}
            disabled={pending}
            onClick={async () => {
              if (!utils.isAddress(address) || !provider) {
                enqueueSnackbar('Wallet not connected', { variant: 'error' });
                return;
              }
              if (
                network !==
                (process.env.REACT_APP_NETWORK_ID
                  ? parseInt(process.env.REACT_APP_NETWORK_ID)
                  : 4)
              ) {
                enqueueSnackbar(
                  `Wrong network. Switch to chain ID ${
                    process.env.REACT_APP_NETWORK_ID || '4'
                  }.`,
                  { variant: 'error' },
                );
                return;
              }
              if (!Fractal) {
                enqueueSnackbar('Fractal Wallet not found.', {
                  variant: 'error',
                });
                return;
              }
              const contract = SmartMoneyWhitelist__factory.connect(
                (contractByNetwork as any)[network],
                provider.getSigner(),
              );
              setPending(true);
              const credKey = enqueueSnackbar(
                'Accept the credential request in your Fractal Wallet...',
              );
              let credentials;
              try {
                credentials = await Fractal.getVerificationRequest(
                  level,
                  {
                    name: 'SmartMoney Whitelist',
                    url: window.location.origin,
                    icon: `${window.location.origin}/favicon.png`,
                  },
                  {
                    identification_document_country: true,
                    residential_address_country: true,
                    wallet_address: true,
                    wallet_currency: true,
                  },
                );
              } catch (e: any) {
                console.error(e);
                closeSnackbar(credKey);
                if (e?.errorCode === 5004) {
                  enqueueSnackbar(
                    'Matching credentials not found in your Fractal Wallet',
                    {
                      variant: 'error',
                    },
                  );
                } else {
                  enqueueSnackbar('Unknown error. Check developer console.', {
                    variant: 'error',
                  });
                }
                return;
              }
              closeSnackbar(credKey);
              if (
                credentials.credential.properties.wallet_address.toLowerCase() !==
                address.toLowerCase()
              ) {
                enqueueSnackbar(
                  'Wallet mismatch. The web3 wallet address must be the same address entered during KYC with Fractal. Switch to the correct wallet and try again',
                  { variant: 'error' },
                );
                return;
              }
              const txKey = enqueueSnackbar(
                'Accept the transaction in your web3 wallet...',
              );
              let tx;
              try {
                tx = await contract.addToWhitelist(
                  credentials.credential.kycType,
                  credentials.credential.countryOfIDIssuance,
                  credentials.credential.countryOfResidence,
                  credentials.credential.rootHash,
                  credentials.credential.issuerSignature,
                );
              } catch (e) {
                console.error(e);
                closeSnackbar(txKey);
                enqueueSnackbar('Unknown error. Check developer console.', {
                  variant: 'error',
                });
                return;
              }
              closeSnackbar(txKey);
              const sentKey = enqueueSnackbar('Transaction sent...');
              const txReceipt = await tx.wait();
              closeSnackbar(sentKey);
              if (txReceipt.status) {
                enqueueSnackbar('You have been whitelisted!', {
                  variant: 'success',
                });
              } else {
                enqueueSnackbar('Whitelisting failed!', { variant: 'error' });
              }
            }}
          >
            Whitelist Me
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
